import React from 'react';
import Navbar from './Navbar';
import { useAppSelector } from './hooks/useLogin';
import Footer from './Footer';

export default function Career() {
    const { email } = useAppSelector((state) => state.auth);

    const styles = {
        page: {
            display: 'flex',
            flexDirection: 'column' as 'column',
            minHeight: '100vh',
            background: 'linear-gradient(135deg, #f0f4f8, #d9e2ec)', // soft gradient background
        },
        content: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column' as 'column',
            justifyContent: 'center' as 'center',
            alignItems: 'center' as 'center',
            textAlign: 'center' as 'center',
            padding: '50px 20px',
        },
        heading: {
            fontSize: '3rem',
            fontWeight: 700,
            marginBottom: '20px',
            color: '#333', // Darker text for heading
        },
        paragraph: {
            fontSize: '1.25rem',
            fontWeight: 400,
            color: '#555',
            maxWidth: '600px',
            lineHeight: 1.6,
            marginBottom: '10px',
        },
        '@media (max-width: 768px)': {
            heading: {
                fontSize: '2.5rem',
            },
            paragraph: {
                fontSize: '1rem',
            },
        },
    };

    return (
        <div style={styles.page}>
            <Navbar isLoggedIn={!!email} userHomeLink="/user-home" />
            
            <section style={styles.content}>
                <h1 style={styles.heading}>Career Opportunities</h1>
                <p style={styles.paragraph}>At the moment, there are no available opportunities. <br/> Please check back soon for future openings.</p>
            </section>
            
            <Footer />
        </div>
    );
}
